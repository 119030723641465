'use client'

import { useCallback, useEffect } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

type CmsFormCaptchaProps = { onLoad: (token: string) => void }

const Loader = ({ onLoad }: CmsFormCaptchaProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('yourAction')
    onLoad(token)
    // Do whatever you want with the token
  }, [executeRecaptcha, onLoad])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    void handleReCaptchaVerify()
  }, [handleReCaptchaVerify])
  return null
}

export const CmsFormCaptcha = (props: CmsFormCaptchaProps) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY || ''}>
      <Loader {...props} />
    </GoogleReCaptchaProvider>
  )
}
